<template>
  <div class="home" id="home" :class="navOpen ? 'navActive' : ''">
    <div
      class="cover"
      @mouseleave="mouseLeave"
      @mousemove="mousemOve"
      ref="cover"
    >
      <div class="cover-bg" id="coverBg"></div>
      <div class="cover-img">
        <div
          class="img-box"
          :style="{
            transform: `translate(${Number(coverOffset.x * 100)}%, ${Number(
              coverOffset.y * 100
            )}%`,
          }"
        >
          <img :src="bgImg" />
        </div>
      </div>
      <!-- Aniley -->
      <i class="iconfont icon-nicetool" id="iconfont"></i>

      <!-- 诗词 -->
      <div class="message" id="message">
        <div class="poetry">{{ poetry.Poetry }}</div>
        <div class="poem-title" v-show="poetry.Poet">
          <span v-show="poetry.Poem_title">《 {{ poetry.Poem_title }} 》</span
          ><span v-show="poetry.Poet === 'null' ? false : true">
            -- {{ poetry.Poet }}</span
          >
        </div>
      </div>
      <!-- 向下 -->
      <div class="down-arrow">
        <div class="down-arrow1"></div>
        <div class="down-arrow2"></div>
        <div class="down-arrow3"></div>
      </div>
    </div>
    <!-- 推文 -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="技术" name="technology"
        ><technology></technology
      ></el-tab-pane>
      <el-tab-pane label="生活" name="life"><life></life></el-tab-pane>
    </el-tabs>
    <!-- menu -->
    <div class="menu" @click="handleNav">
      <i class="iconfont" :class="navOpen ? 'icon-closel' : 'icon-menu'"></i>
    </div>
    <!-- nav -->
    <div class="nav" @mouseleave="mouseLeave" @mousemove="mousemOve"></div>
  </div>
</template>

<script>
import rgbaster from "rgbaster";
import { _GetGuShiCi, _GetArticleList } from "@/api/home.js";
import technology from "@/components/technology.vue";
import life from "@/components/life.vue";
export default {
  components: { technology, life },
  name: "home",
  data() {
    return {
      coverOffset: { x: -0.5, y: -0.5 }, //图片位移量
      mousePosition: { x: null, y: null }, //鼠标当前位置
      imgBox: null, //盒子大小
      start: true,
      // 古诗词
      poetry: {
        Poem_title: null,
        Poet: null,
        Poetry: null,
      },
      // 动态组件
      activeName: "technology",
      // 是否点开nav
      navOpen: false,
      //   bgImg: require("@/assets/home.png"),
      bgImg:
        "https://i.imgs.ovh/2023/10/24/FAe4e.jpeg",
      scroll: 0,
    };
  },
  created() {
    this.getGuShiCi();
  },
  mounted() {
    this.imgBox = {
      x: this.$refs.cover.clientWidth,
      y: this.$refs.cover.clientHeight,
    };
    // let result = rgbaster(this.bgImg, {
    //   ignore: ["rgb(255,255,255)", "rgb(0,0,0)"],
    //   scale: 0.1,
    // });
    // result.then((res) => {
    //   let color = res[0].color.replace("rgb(", "").replace(")", "");
    //   document.getElementById("coverBg").style.background =
    //     "rgba(" + color + ",0.7)";
    //   let RgbValueArry = color.split(",");
    //   let $grayLevel =
    //     RgbValueArry[0] * 0.299 +
    //     RgbValueArry[1] * 0.587 +
    //     RgbValueArry[2] * 0.114;
    //   if ($grayLevel >= 192) {
    //     // 文字为深色
    //     document.getElementById("message").style.color = "#000";
    //     document.getElementById("iconfont").style.color = "#000";
    //   } else {
    //     // 文字为浅色
    //     document.getElementById("message").style.color = "#fff";
    //     document.getElementById("iconfont").style.color = "#fff";
    //   }
    // });
    document
      .getElementById("home")
      .addEventListener("scroll", this.handleScroll);
  },

  methods: {
    // 鼠标离开恢复位置
    mouseLeave() {
      this.mousePosition = { x: null, y: null };
      this.coverOffset = { x: -0.5, y: -0.5 };
      this.start = true;
    },
    // 图片偏移
    mousemOve(e) {
      // 记录开始时的鼠标位置
      if (this.start) {
        this.mousePosition.x = e.offsetX;
        this.mousePosition.y = e.offsetY;
      }
      this.start = false;
      let maxOffset = 0.1 / 1.1 / 2; //单边最大偏移百分比
      this.coverOffset.x =
        -((e.offsetX - this.mousePosition.x) / this.imgBox.x) * maxOffset - 0.5;
      this.coverOffset.y =
        -((e.offsetY - this.mousePosition.y) / this.imgBox.y) * maxOffset - 0.5;
    },
    // 获取古诗词
    async getGuShiCi() {
      let { data } = await _GetGuShiCi();
      this.poetry = {
        Poem_title: data.title,
        Poet: data.author,
        Poetry: data.min_content,
      };
    },
    // 开关导航
    handleNav() {
      this.navOpen = !this.navOpen;
    },
    handleScroll(e) {
      this.scroll = document.getElementById("home").scrollTop;
    },
  },
  activated() {
    document.getElementById("home").scrollTop = this.scroll;
  },
};
</script>
<style lang="less" scoped>
a {
  color: #262626;
}
.home {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    background: #dbdbdb;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    background: #f6f6f6;
  }
  .cover {
    // position: relative;
    // z-index: 9999;
    width: 100%;
    height: 100vh;
    .cover-bg {
      position: absolute;
      top: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      background-color: rgba(176, 14, 37, 0.7);
      clip-path: polygon(0 0, 25% 0, 60% 100%, 0% 100%);
      pointer-events: none;
    }
    .cover-img {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      .img-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 110%;
        height: 110%;
        transition: all 0.1s;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .message {
      position: absolute;
      top: 50%;
      left: 5%;
      transform: translateY(-50%);
      z-index: 10;
      color: white;
      font-size: 28px;
      pointer-events: none;
      max-width: 35%;
      .poetry {
        font-size: 28px;
      }
      .poem-title {
        line-height: 24px;
        text-align: right;
        font-size: 14px;
      }
    }
    .down-arrow {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      pointer-events: none;
      > div {
        width: 25px;
        height: 15px;
        background-image: url("../assets/images/downArrow.png");
        animation-name: arrow;
        animation-duration: 1.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
      .down-arrow1 {
        animation-delay: 0s;
      }
      .down-arrow2 {
        animation-delay: 0.5s;
      }
      .down-arrow3 {
        animation-delay: 1s;
      }
      @keyframes arrow {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }
  }
  ::v-deep .el-tabs__nav {
    float: none;
    width: 100px;
    text-align: center;
    margin: 10px auto;
    .el-tabs__item {
      font-size: 18px;
    }
    .el-tabs__item.is-active {
      color: #cc0000;
    }
    .el-tabs__active-bar {
      background-color: #cc0000;
    }
    .el-tabs__item:hover {
      color: #cc0000;
    }
  }
  ::v-deep .el-tabs__nav-wrap::after {
    content: "";
    position: static !important;
    width: 0;
    height: 0;
    background-color: #fff;
    z-index: 0;
  }
  .icon-nicetool {
    position: absolute;
    top: 10%;
    left: 3%;
    font-size: 100px;
    z-index: 999;
    color: #fff;
    pointer-events: none;
    transition: all 0.5s;
  }
  .menu {
    z-index: 99999;
    position: absolute;
    top: 11%;
    right: 3%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
    cursor: pointer;
    color: #ff3600;
    text-align: center;
    opacity: 0.7;
    background: hsla(0, 0%, 100%, 0.7);
    // pointer-events: none;
    i {
      font-size: 28px;
    }
  }
  .nav {
    position: fixed;
    left: 0;
    top: -100%;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    background: hsla(0, 0%, 100%, 0.96);
    transition: top 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  }
}
.navActive {
  .icon-nicetool {
    color: #000;
  }
  .nav {
    top: 0;
  }
}
@media screen and (max-width: 480px) {
  .home {
    .icon-nicetool {
      font-size: 70px;
    }
  }
}
@media screen and (max-width: 900px) {
  .home {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    // .cover {
    //   .cover-bg {
    //     clip-path: none;
    //     background: rgba(176, 14, 37, 0.35);
    //   }
    // }
    .menu {
      background: hsla(0, 0%, 100%, 1);
    }
  }
}
@media screen and (max-width: 1200px) {
  .home {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
