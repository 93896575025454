import service from "@/utils/request.js";
// 文章获取
export function _GetArticleList(params) {
  return service({
    url: "/getArticleList",
    method: "get",
    params,
  });
}

// 获取古诗词
export function _GetGuShiCi() {
  return service({
    url: "/getGuShiCi",
    method: "get",
  });
}
